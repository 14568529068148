@import '../../../layout/variables';
@import '../../../layout/sass/mixins';

/* Workorder */

.new-workorder-category{
    .p-dropdown-item {
        white-space: normal;
        text-align: left;
    }
}

.app-inline-fileupload{
    display: inline-block;

    .p-fileupload-buttonbar{
        padding: 0px;
        border: none;
    }

    .p-fileupload-content{
        display: none;
    }
}

.workorder-item {
    width: 100%;
    margin: 0.5em;

    .wo-text-container{
        max-width: 1000px;
    
        .wo-text{
            display: grid;
        }
    }
}

.workorder-item-header {
    
    .summary {
        position: relative;
        
        .wo-notification button{
            cursor: auto;
        }

        .title {
            font-size: 20px;
        }
        
        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }
        
        .state {
            color: #ffffff;
            font-size: 20px;
            padding: 0px 14px 5px;
            margin: 0px;
            @include border-radius($borderRadius);
            
            &.closed {
                background-color: #bdbdbd;
            }
            
            &.working {
                background-color: #f9c851;
            }
            
            &.new {
                background-color: #007be5;
            }

            &.waiting {
                background-color: hotpink;
            }
        }
    }

    .p-overlay-badge{
        font-size: 1.5rem;
    }
}
