//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-toast-top-right {
    top: 10px;
    right: 10px;
}

.p-toast {
    width: 20rem;
}

.p-dataview-emptymessage{
    font-weight: bold;
    padding: 1rem !important;
}