.layout-profile {
    text-align: center;
    padding: 20px 0;

    .layout-profile-item {
        margin-bottom: .75em;
        @include transition(color $transitionDuration);
    }
}

.layout-sidebar-light {
    .layout-profile {
        .layout-profile-item {
            color: $menuitemColor;
    
            &:hover {
                color: $menuitemHoverColor;
            }
        }      
    }
}

.layout-sidebar-dark {
    .layout-profile {
        .layout-profile-item {
            color: $menuitemDarkColor;
    
            &:hover {
                color: $menuitemDarkHoverColor;
            }
        }
    }
}

.pi-telegram{
    background: url(../images/telegram_logo_small.png);
    width: 24px;
    height: 22px;
}