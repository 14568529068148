@import '../../../layout/variables';
@import '../../../layout/sass/mixins';

/* Payments */

.payment-item {
    width: 100%;
    margin: 0.5em;
}

.payment-item-header {
    
    .summary {
        position: relative;
        
        .title {
            font-size: 20px;
        }
        
        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }
        
        .state {
            color: #ffffff;
            font-size: 20px;
            padding: 0px 14px 5px;
            margin: 0px;
            @include border-radius($borderRadius);
            
            &.payed {
                background-color: #20d077;
            }
            
            &.unpayed {
                background-color: #f9c851;
            }
            
            &.payed-part {
                background-color: #007be5;
            }

            &.error {
                background-color: hotpink;
            }
        }
    }
}
